import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: '', // e.g: 'Name | Developer'
  lang: '', // e.g: en, es, fr, jp
  description: '', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hallo, ik ben',
  name: 'Jordy Krudde',
  subtitle: 'Junior web developer',
  cta: 'Over mij',
};

// ABOUT DATA
export const aboutData = {
  img: 'profielFoto.jpg',
  paragraphOne: 'Mijn naam is Jordy Krudde, Ik ben 22 jaar oud en ik ben een junior web developer.',
  paragraphTwo: 'Ik kom net vers uit de opleiding applicatieontwikkelaar aan het graafschapcollege.',
  paragraphThree: 'Op deze site kun je mijn vorige projecten en mijn skills bekijken.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'dtvhome.png',
    title: 'Doetinchemse tennis vereniging',
    info: 'Doetinchemse tennisvereniging is een fictieve tennisclub en is gemaakt voor de opleiding Applicatie-Media ontwikkelaar op het graafschapcollege.',
    info2: '',
    url: 'https://jkrudde.gc-webhosting.nl/examen-project/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'planningsmonitorHome.png',
    title: 'Planningsmonitor',
    info: 'Planningsmonitor is een web applicatie die ik gemaakt heb voor mijn eind stage bij Doit online media / Exapps',
    info2: 'Met deze applicatie kan de gebruiker projecten bijhouden en er zowel taken als notities aan koppelen.',
    url: 'https://github.com/JordyKrudde/planningsmonitor_backup',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'musidesk.png',
    title: 'E-Musidesk',
    info: 'E-musidesk is een project waar ik op mijn stage bij blik op online aan heb gewerkt. Werkzaamheden waren: design inplementeren, testplan maken, usecases maken en tickets oplossen ',
    info2: '',
    url: 'https://www.e-musidesk.nl/users/auth/login',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'journy.png',
    title: 'Journey App design',
    info: 'Journey is een fictief trein reis bedrijf waar ik een ontwerp voor heb gemaakt  Dit ontwerp is gemaakt in Adobe XD',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Neem contact met mij op!',
  btn: 'Stuur een mail',
  email: 'jordykrudde1@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/jordy-krudde-7276a7184/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/JordyKrudde',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
